import React ,{ useEffect, useState} from 'react'
import Colours from '../data/colours.json'
import ReactChart from './charts/ReactChart'

import api from '../utils/ServiceApiSimilarity'
import { SERVICE_URL } from '../config'
import axios from 'axios'
import { Divider } from '@mui/material';
import _ from 'lodash'
import TimeSeriesCharts from '../components/charts/TimeSeriesCharts'
import {Grid, Typography, Container, Box, Paper} from '@material-ui/core'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Auth from '../utils/Auth';
import Util from '../utils/Util';
import CircularIndeterminate from './CircularIndeterminate'

const chartColors = {
  primary: [
    '#2196F3',  // Blue
    '#1976D2',  // Darker Blue
    '#64B5F6',  // Lighter Blue
    '#0D47A1',  // Deep Blue
    '#42A5F5',  // Sky Blue
  ],
  secondary: [
    '#4CAF50',  // Green
    '#2E7D32',  // Dark Green
    '#81C784',  // Light Green
    '#1B5E20',  // Deep Green
    '#66BB6A',  // Mint Green
  ],
  accent: [
    '#FF9800',  // Orange
    '#F57C00',  // Dark Orange
    '#FFB74D',  // Light Orange
    '#E65100',  // Deep Orange
    '#FFA726',  // Soft Orange
  ]
};

export default function Dashboard(props){

    const [companiesForDashboard, setCompaniesForDashboard] = useState([]);
    const [designationForDashboard, setDesignationForDashboard] = useState([]);
    const [nativeCityForDashboard, setNativeCityForDashboard] = useState([]);
    const [workCityForDashboard, setWorkCityForDashboard] = useState([]);
    const [skillForDashboard, setSkillForDashboard] = useState([]);
    const [checkedTs, setCheckedTs] = React.useState(true);
    const [userRecord, setUserRecord] = useState({})
    const handleChangeTs = () => {
        setCheckedTs((prev) => !prev);
      };
    
    useEffect(async () => {
        await Util.getUserDetails(await Auth.getUserID());
        setUserRecord(Util.userRecord);
    }, [])

    const setData = (type, chart)=>{
        if(type === "skills"){
            setSkillForDashboard(chart);
        }else if(type === "companies"){
            setCompaniesForDashboard(chart);
        }else if(type === "nativeCity"){
            setNativeCityForDashboard(chart);
        }else if(type === "workCity"){
            setWorkCityForDashboard(chart);
        }else if(type === "designation"){
            setDesignationForDashboard(chart);
        }
    }

    const setLabel = (type)=>{
        if(type === "skills"){
            return "skills"
        }else if(type === "companies"){
            return "companies"
        }else if(type === "nativeCity"){
            return "native cities"
        }else if(type === "workCity"){
            return "work cities"
        }else if(type === "designation"){
            return "designations"
        }
    }
    const getDataCharts = (type, url) => {
        let chart = {}
        axios.get(url)
                .then(response => {
                    if(!_.isEmpty(response.data)) {
                        let labels = []
                        let dataPoints = []
                        let colourSet = []
                        let array = response.data
                        
                        // Sort data by count in descending order and limit to top 5
                        array = array.sort((a, b) => b.count - a.count).slice(0, 5)
                        
                        for(let i in array) {
                            labels.push(array[i].name)
                            dataPoints.push(array[i].count)
                            // Use different color schemes for different charts
                            colourSet.push(
                                type === 'skills' ? chartColors.primary[i] :
                                type === 'companies' ? chartColors.secondary[i] :
                                chartColors.accent[i]
                            )
                        }
                        
                        chart["labels"] = labels;
                        let dataSet = {
                            label: setLabel(type),
                            data: dataPoints,
                            backgroundColor: colourSet,
                            borderColor: colourSet,
                            borderWidth: 1,
                            borderRadius: 5,
                            hoverOffset: 4
                        }
                        
                        chart["datasets"] = [dataSet]
                    }
                    setData(type, chart)
                })
    }
    
        //Cannot call useEffect in loop.

        useEffect(()=>{
            getDataCharts ('companies', SERVICE_URL+api.companiesDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('designation', SERVICE_URL+api.designationDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('workCity', SERVICE_URL+api.workCityDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('nativeCity', SERVICE_URL+api.nativeCityDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('skills', SERVICE_URL+api.skillDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
    
        
    
   
       
    return (
        <Container maxWidth="lg" sx={{ py: { xs: 3, sm: 4, md: 5 } }}>
            <Box sx={{ 
                backgroundColor: '#fff',
                borderRadius: 2,
                boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
                overflow: 'hidden'
            }}>
                <Grid container spacing={3}>
                    {/* History Section */}
                    <Grid item xs={12}>
                        <Box sx={{ 
                            p: { xs: 2, sm: 3 },
                            borderBottom: '1px solid #eee'
                        }}>
                            <Typography 
                                onClick={handleChangeTs} 
                                color='primary' 
                                variant='h6'
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    transition: 'color 0.2s',
                                    '&:hover': {
                                        color: 'primary.dark'
                                    }
                                }}
                            >
                                History {checkedTs ? <ExpandLess /> : <ExpandMore />}
                            </Typography>

                            <Collapse in={checkedTs} sx={{ mt: 2 }}>
                                {!_.isEmpty(userRecord) ? 
                                    <TimeSeriesCharts userId={userRecord.id}/> : 
                                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                        <CircularIndeterminate />
                                    </Box>
                                }
                            </Collapse>
                        </Box>
                    </Grid>

                    {/* Sample Data Header */}
                    <Grid item xs={12}>
                        <Typography 
                            color='primary' 
                            variant='h6' 
                            sx={{ 
                                px: { xs: 2, sm: 3 },
                                pb: 2
                            }}
                        >
                            Sample Data in Linkout:
                        </Typography>
                    </Grid>

                    {/* Charts Grid */}
                    <Grid item xs={12}>
                        <Box sx={{ px: { xs: 2, sm: 3 }, pb: 3 }}>
                            <Grid container spacing={3}>
                                {/* Work City Chart */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Paper elevation={2} sx={{
                                        p: 2,
                                        height: '100%',
                                        borderRadius: 2,
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.12)'
                                        }
                                    }}>
                                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                            Work Cities Distribution
                                        </Typography>
                                        {!_.isEmpty(skillForDashboard) ? 
                                            <ReactChart 
                                                type='bar' 
                                                data={workCityForDashboard}
                                                options={{
                                                    indexAxis: 'y',
                                                    plugins: {
                                                        legend: { display: false }
                                                    },
                                                    scales: {
                                                        x: { beginAtZero: true }
                                                    }
                                                }}
                                            /> : 
                                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                                <CircularIndeterminate color={Colours[53].name}/>
                                            </Box>
                                        }
                                    </Paper>
                                </Grid>

                                {/* Native City Chart */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Paper elevation={2} sx={{
                                        p: 2,
                                        height: '100%',
                                        borderRadius: 2,
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.12)'
                                        }
                                    }}>
                                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                            Native Cities Distribution
                                        </Typography>
                                        {!_.isEmpty(nativeCityForDashboard) ? 
                                            <ReactChart 
                                                type='bar' 
                                                data={nativeCityForDashboard}
                                                options={{
                                                    indexAxis: 'y',
                                                    plugins: {
                                                        legend: { display: false }
                                                    },
                                                    scales: {
                                                        x: { beginAtZero: true }
                                                    }
                                                }}
                                            /> : 
                                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                                <CircularIndeterminate color={Colours[53].name}/>
                                            </Box>
                                        }
                                    </Paper>
                                </Grid>

                                {/* Skills Chart */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Paper elevation={2} sx={{
                                        p: 2,
                                        height: '100%',
                                        borderRadius: 2,
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.12)'
                                        }
                                    }}>
                                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                            Skills Distribution
                                        </Typography>
                                        {!_.isEmpty(skillForDashboard) ? 
                                            <ReactChart 
                                                type='bar' 
                                                data={skillForDashboard}
                                                options={{
                                                    plugins: {
                                                        legend: { display: false },
                                                        title: { display: false }
                                                    },
                                                    scales: {
                                                        y: { beginAtZero: true }
                                                    },
                                                    animation: {
                                                        duration: 2000,
                                                        easing: 'easeInOutQuart'
                                                    }
                                                }}
                                            /> : 
                                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                                <CircularIndeterminate color={Colours[53].name}/>
                                            </Box>
                                        }
                                    </Paper>
                                </Grid>

                                {/* Companies Chart */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Paper elevation={2} sx={{
                                        p: 2,
                                        height: '100%',
                                        borderRadius: 2,
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.12)'
                                        }
                                    }}>
                                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                            Companies Distribution
                                        </Typography>
                                        {!_.isEmpty(companiesForDashboard) ? 
                                            <ReactChart 
                                                type='doughnut' 
                                                data={companiesForDashboard}
                                                options={{
                                                    plugins: {
                                                        legend: { position: 'bottom' }
                                                    },
                                                    cutout: '60%',
                                                    animation: {
                                                        animateRotate: true,
                                                        animateScale: true
                                                    }
                                                }}
                                            /> : 
                                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                                <CircularIndeterminate color={Colours[53].name}/>
                                            </Box>
                                        }
                                    </Paper>
                                </Grid>

                                {/* Designation Chart */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Paper elevation={2} sx={{
                                        p: 2,
                                        height: '100%',
                                        borderRadius: 2,
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.12)'
                                        }
                                    }}>
                                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                            Designations Distribution
                                        </Typography>
                                        {!_.isEmpty(designationForDashboard) ? 
                                            <ReactChart 
                                                type='pie' 
                                                data={designationForDashboard}
                                                options={{
                                                    plugins: {
                                                        legend: { position: 'bottom' }
                                                    },
                                                    animation: {
                                                        animateRotate: true,
                                                        animateScale: true
                                                    }
                                                }}
                                            /> : 
                                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                                <CircularIndeterminate color={Colours[53].name}/>
                                            </Box>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

