import React, { useState } from "react";
import LoginForm from '../Login-new_form'
import SignupLatest from '../SignupLatest'
import { AppBar, Grid, Paper, CardMedia, BottomNavigation, Button, Dialog, Typography } from "@material-ui/core";

import VerificationComp from '../Verfication'
import SetNewPassword from "../SetNewPassword";
import Container from '@mui/material/Container';

import { styled } from '@mui/material/styles';
import logo from '../../data/logo3.png'
import MyDialog from './MyDialog'

import { Route} from 'react-router-dom';



const UnauthenticatedView =  ()=>{
    

        const Item = styled(Paper)(({ theme }) => ({
            backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            ...theme.typography.body2,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
          }));

        const [openSignup, setOpenSignup] = useState(false);

        const handleOpenSignup = () => {
            setOpenSignup(true);
        };

        const handleCloseSignup = () => {
            setOpenSignup(false);
        };

        return(
            <div align="left" style={{ 
                minHeight: '100vh',
                background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${logo})`,
                backgroundSize: '400px',
                backgroundPosition: 'center',
                backgroundRepeat: 'repeat',
                position: 'relative',
                paddingBottom: '100px'
            }}>
                <Route path='/verification/:id' exact component={VerificationComp}/>
                <Route path='/reset/:id' exact component={SetNewPassword}/>
                
                <Container sx={{border:0, height :'100%'}} maxWidth="md">
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} md={12} lg={8} style={{ marginTop: '80px' }}>
                            <Item><LoginForm /></Item>
                        </Grid>
             
                        <Grid item xs={12} md={12} lg={8}>
                            <Item>
                                <div style={{ 
                                    padding: '20px',
                                    borderTop: '1px solid #e0e0e0',
                                    marginTop: '20px'
                                }}>
                                    <div style={{ 
                                        textAlign: 'center',
                                        marginBottom: '15px',
                                        color: '#666'
                                    }}>
                                        New to Linkout?
                                    </div>
                                    <Button 
                                        variant="contained" 
                                        color="secondary"
                                        onClick={handleOpenSignup}
                                        fullWidth
                                        style={{
                                            padding: '10px',
                                            fontSize: '1.1rem',
                                            textTransform: 'none',
                                            backgroundColor: '#42b72a',
                                            '&:hover': {
                                                backgroundColor: '#36a420'
                                            }
                                        }}
                                    >
                                        Create New Account
                                    </Button>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </Container>

                <Dialog 
                    open={openSignup} 
                    onClose={handleCloseSignup}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        style: {
                            padding: '20px',
                            margin: '20px',
                            maxHeight: '90vh',
                            overflowY: 'auto'
                        }
                    }}
                >
                    <SignupLatest />
                </Dialog>

                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#f5f5f5',
                    borderTop: '1px solid #e0e0e0',
                    padding: '10px 0',
                    width: '100%',
                    zIndex: 1000
                }}>
                    <Container maxWidth="lg">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <MyDialog />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary" align="center" style={{ 
                                    fontSize: '0.75rem',
                                    marginTop: '8px'
                                }}>
                                    © {new Date().getFullYear()} Linkout. All rights reserved.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        )
    }


export default UnauthenticatedView;