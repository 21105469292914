import React, { Component } from 'react'
import {Input, Button, IconButton, Typography} from '@material-ui/core'
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularIndeterminate from './CircularIndeterminate';
import {SlCloudUpload} from "react-icons/sl";
import {RiUploadCloud2Fill} from "react-icons/ri";




class FileUpload extends Component{

    componentDidMount(){
    }

    render(props){
       
          
        return(
         <div>
            
             <Typography variant="caption" gutterBottom>
                {this.props.uploadMessage}
                
            <Input style={{ display: 'none' }} id={this.props.scenario} type="file" name={this.props.scenario ? this.props.scenario : "uploadedFile"} onChange={this.props.onChangeHandler} inputProps={{ multiple: this.props.multiFile }}/>
            
            <label htmlFor={this.props.scenario}>
                    <IconButton color="primary" component="span">
                        { this.props.dataLoaded  ? 
                        <RiUploadCloud2Fill fontSize={'20px'}  color={ 'primary'} /> 
                        : <SlCloudUpload fontSize={ '20px'}  color={'primary' } /> 
                        }
                    </IconButton>
            </label>
            <Button style={{display:this.props.dataLoaded ? 'inline' : 'none'}} disabled={this.props.isSubmitting} onClick={this.props.onClickHandler} color="secondary">Submit</Button>
            {this.props.isSubmitting ? <CircularIndeterminate/> : null}
            <Typography variant="caption">
                Please upload supporting documentation to verify your professional achievements and credentials. This helps us provide more accurate analysis and recommendations.
            </Typography>
            </Typography>
            
        </div>
        )
    }
}

export default FileUpload