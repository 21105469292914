import React from 'react';
import { Typography, Box, Container, Link, Grid, Paper } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const Contact = () => {
  return (
    <Box className="contact-page" sx={{ 
      padding: { xs: '20px', md: '40px' },
      color: '#333'
    }}>
      <Container maxWidth="md">
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          sx={{ 
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: '30px',
            color: '#1a73e8'
          }}
        >
          Contact Us
        </Typography>

        <Box sx={{ 
          '& p': {
            marginBottom: '1.2em',
            lineHeight: '1.7',
            fontSize: { xs: '0.95rem', md: '1rem' },
            color: '#555'
          }
        }}>
          <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
            {/* Support Section */}
            <Grid item xs={12}>
              <Paper elevation={2} sx={{ 
                p: { xs: 2, sm: 2, md: 3 },
                backgroundColor: '#f8f9fa'
              }}>
                <Typography variant="h6" gutterBottom sx={{ 
                  color: '#1a73e8', 
                  fontWeight: 500,
                  fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.25rem' }
                }}>
                  Support & Submissions
                </Typography>
                <Typography paragraph>
                  For any queries regarding your submissions or technical support, please contact:
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  <EmailIcon sx={{ color: '#1a73e8' }} />
                  <Link href="mailto:admin@linkout.co.in" sx={{ color: '#1a73e8', textDecoration: 'none' }}>
                    admin@linkout.co.in
                  </Link>
                </Box>
                <Typography paragraph sx={{ fontSize: '0.9rem', color: '#666' }}>
                  Please include your username in all correspondence for faster assistance.
                </Typography>
              </Paper>
            </Grid>

            {/* Business & Marketing Section */}
            <Grid item xs={12}>
              <Paper elevation={2} sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#1a73e8', fontWeight: 500 }}>
                  Business & Marketing Opportunities
                </Typography>
                <Typography paragraph>
                  Partner with Linkout to reach a growing community of professionals:
                </Typography>
                <ul style={{ marginBottom: '1.2em', paddingLeft: '20px' }}>
                  <li>Access to a targeted audience of skilled professionals</li>
                  <li>Custom advertising solutions for your business needs</li>
                  <li>Sponsored content opportunities</li>
                  <li>Partnership and collaboration possibilities</li>
                </ul>
                <Typography paragraph>
                  For business inquiries and marketing opportunities, reach out to:
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  <EmailIcon sx={{ color: '#1a73e8' }} />
                  <Link href="mailto:marketing@linkout.co.in" sx={{ color: '#1a73e8', textDecoration: 'none' }}>
                    marketing@linkout.co.in
                  </Link>
                </Box>
              </Paper>
            </Grid>

            {/* Response Time Section */}
            <Grid item xs={12}>
              <Paper elevation={2} sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#1a73e8', fontWeight: 500 }}>
                  Our Commitment
                </Typography>
                <Typography paragraph>
                  We strive to respond to all inquiries within 24-48 business hours. Your communication is important to us, and we're committed to providing timely and helpful responses to all our users and partners.
                </Typography>
                <Typography paragraph>
                  For urgent matters, please mark your email as "High Priority" and we'll do our best to expedite our response.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
