import React ,{useState} from 'react'
import _ from 'lodash';
import { useForm } from 'react-hook-form'
import CircularIndeterminate from '../CircularIndeterminate';
import MyAutoComplete from '../reusable/MyAutoComplete'
import { Box,Button,Paper,Typography, useMediaQuery, useTheme } from '@mui/material';
import companiesListValues from '../../data/companies.json'
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import Util from '../../utils/Util';
import MyAlert from '../Alerts';
import BasicModal from '../reusable/MyModal';

const Comparision = (props)=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setvalue] = useState('')
    let [tempTargetCompany , setTempTargetCompany] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const [responseFromGpt, setResponseFromGpt] = useState('')
    //Modal Start
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState('')

    //Modal End
    const targetCompanyHandler = (company)=>{
        if(_.isEmpty(company)){
          setTempTargetCompany('')
        }else{
          setTempTargetCompany(company)
        }
      }

      const comparisionHandler = async ()=>{
        if(_.isEmpty(tempTargetCompany)){
          setResponseFromGpt('Select a company to find predictions')
        }else{
          let userId = props.userId
            setShowLoader(true)
            console.log('Sending call to GPT')
            let response = await Util.getPrediction(userId, tempTargetCompany.id);
            
            console.log(JSON.stringify(response))
            let responseData;
              if(response.success){
                responseData = response?.message?.data
              }else{
                responseData = response?.message;
              }
               
              console.log(JSON.stringify(responseData))
              setResponseFromGpt(responseData)
              setShowLoader(false)
           
              setOpen(true)
              setMessage({body: responseData , title : "Analysis for "+tempTargetCompany.name})
              
            
        
        }
          
      }
      

      const {control, handleSubmit , getValues, setValue} = useForm({
        });

        const printSelectedTargetCompany=(company)=>{

            return <div>
                   <Typography variant="h6" color="primary">{company?.name}</Typography> 
                   </div>
          }

      
        return(
            
            <Box sx={{ padding: '24px' }}>
                {isMobile ? (
                    <Paper 
                        elevation={2} 
                        sx={{ 
                            padding: '24px', 
                            marginBottom: '24px',
                            backgroundColor: '#f5f5f5',
                            borderLeft: '4px solid #1976d2'
                        }}
                    >
                        <Typography variant="h6" color="primary" gutterBottom>
                            Desktop Only Feature
                        </Typography>
                        <Typography variant="body1">
                            The Find your fit feature is optimized for larger screens. 
                            Please access this feature on a desktop or tablet device for the best experience.
                        </Typography>
                    </Paper>
                ) : (
                    <>
                        <Paper 
                            elevation={2} 
                            sx={{ 
                                padding: '24px', 
                                marginBottom: '24px',
                                backgroundColor: '#f5f5f5',
                                borderLeft: '4px solid #1976d2'
                            }}
                        >
                            <Typography variant="h6" color="primary" gutterBottom>
                                Career Fit Analysis
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Want to know if you're a good fit for your target company? Follow these steps:
                            </Typography>
                            <ol style={{ marginLeft: '20px' }}>
                                <li>
                                    <Typography variant="body1" paragraph>
                                        Select your target company from the dropdown below
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" paragraph>
                                        Click "Predict my chances" to get a detailed analysis
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" paragraph>
                                        Review the AI-powered assessment of your profile fit
                                    </Typography>
                                </li>
                            </ol>
                            <Typography variant="body2" color="textSecondary">
                                Our AI analyzes your experience, skills, and education against the company's typical hiring patterns.
                            </Typography>
                        </Paper>

                        <Paper elevation={2} sx={{ 
                            padding: '24px', 
                            marginBottom: '24px',
                            width: '100%',
                            overflow: 'visible',
                            position: 'relative',
                            zIndex: 1
                        }}>
                            <MyAutoComplete  
                                handler={targetCompanyHandler} 
                                name="nTargetCompanies" 
                                control={control} 
                                listValues={companiesListValues} 
                                label="Select your target company"
                                sx={{ 
                                    width: '100%',
                                    '& .MuiAutocomplete-popper': {
                                        zIndex: 1500,
                                        position: 'fixed !important',
                                        left: '50% !important',
                                        transform: 'translateX(-50%) !important',
                                        width: 'auto !important',
                                        maxWidth: {
                                            xs: 'calc(100% - 48px)', // Slightly more padding
                                            sm: 'calc(100% - 48px)'
                                        }
                                    },
                                    '& .MuiPaper-root': {
                                        width: '100%'
                                    }
                                }}
                            />
                            
                            {tempTargetCompany && (
                                <Paper 
                                    elevation={0} 
                                    variant="outlined" 
                                    sx={{ 
                                        padding: '16px', 
                                        marginTop: '16px',
                                        backgroundColor: '#e3f2fd',
                                        borderRadius: '8px'
                                    }}
                                >
                                    <Typography variant="h6" color="primary">
                                        Selected Company: {tempTargetCompany?.name}
                                    </Typography>
                                </Paper>
                            )}
                        </Paper>

                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                disabled={!tempTargetCompany} 
                                onClick={comparisionHandler}
                                size="large"
                                sx={{
                                    minWidth: '200px',
                                    '&.Mui-disabled': {
                                        backgroundColor: '#e0e0e0',
                                        color: '#9e9e9e'
                                    }
                                }}
                            >
                                {showLoader ? (
                                    <CircularIndeterminate sx={{ marginRight: '8px' }} />
                                ) : (
                                    'Predict my chances'
                                )}
                            </Button>
                        </Box>

                        <Paper elevation={0}>
                            <BasicModal 
                                open={open} 
                                handleOpen={handleOpen} 
                                handleClose={handleClose} 
                                message={message}
                            />
                        </Paper>
                    </>
                )}
            </Box>
            
        
        )
    }


export default Comparision;