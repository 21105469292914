import React, { useRef } from 'react';
import Slider from 'react-slick';
import same_ctc from '../../images/same_ctc.png';
import higher_ctc from '../../images/higher_ctc.png';
import lesser_ctc from '../../images/lesser_ctc.png';
import { Typography, Container, Box, Paper, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const SliderItem = ({ title, description }) => (
  <div>
    <Paper elevation={0} sx={{ p: 2, backgroundColor: '#f8f9fa' }}>
      <Typography variant="h6" gutterBottom align="center" sx={{ color: '#1a73e8' }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      </Box>
      <Typography variant="body2" color="textSecondary" align="center">
        {description}
      </Typography>
    </Paper>
  </div>
);

const Services = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: "slick-dots custom-dots",
  };

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <Box className="services-page" sx={{ 
      padding: { xs: '20px', sm: '30px', md: '40px' },
      color: '#333'
    }}>
      <Container maxWidth="md">
      <Paper 
          elevation={0} 
          sx={{ 
            p: { xs: 2, sm: 3, md: 4 }, 
            mt: 6,
            spacing: 2,
            backgroundColor: '#f8f9fa',
            position: 'relative'
          }}
        >
          <Typography variant="body1" paragraph sx={{ textAlign: 'center', color: '#666' }}>
            Unlock comprehensive career insights through our advanced analytics platform. From compensation 
            benchmarking and peer comparisons to industry trends and growth trajectories, our tools 
            provide valuable perspectives for your career decisions. Regular access ensures you stay 
            current with the latest market dynamics and personalized insights.
          </Typography>
        </Paper>
        

        <Box sx={{ 
          position: 'relative',
          '.custom-dots': {
            bottom: '-30px',
            '& li button:before': {
              fontSize: '12px',
              color: '#1a73e8',
            },
            '& li.slick-active button:before': {
              color: '#1a73e8',
            }
          },
          mb: 8
        }}>
          <IconButton
            onClick={previous}
            sx={{
              position: 'absolute',
              left: { xs: '-20px', sm: '-40px' },
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <IconButton
            onClick={next}
            sx={{
              position: 'absolute',
              right: { xs: '-20px', sm: '-40px' },
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>

          <Slider ref={sliderRef} {...settings}>
            <SliderItem 
              title="Same CTC Analysis"
              description="View how many professionals share your compensation range"
            />
            <SliderItem 
              title="Higher CTC Insights"
              description="See the number of professionals with higher compensation"
            />
            <SliderItem 
              title="Market Trends"
              description="Explore compensation patterns across your industry"
            />
            <SliderItem 
              title="Same Native City"
              description="Discover professionals from your hometown"
            />
            <SliderItem 
              title="Same Work City"
              description="See how many professionals work in your current city"
            />
            <SliderItem 
              title="Common Company History"
              description="Find the count of professionals with shared company backgrounds"
            />
            <SliderItem 
              title="Similar Skills"
              description="View the number of professionals with matching skill sets"
            />
            <SliderItem 
              title="Same Current Company"
              description="See how many professionals work at your current company"
            />
            <SliderItem 
              title="Same Designation"
              description="View professionals count with your current designation"
            />
          </Slider>
        </Box>
         
        
      </Container>

      <style jsx global>{`
        .slick-dots {
          bottom: -30px !important;
        }
        .slick-dots li button:before {
          font-size: 12px !important;
          color: #1a73e8 !important;
        }
        .slick-dots li.slick-active button:before {
          color: #1a73e8 !important;
        }
      `}</style>
    </Box>
  );
};

export default Services;
