import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import About from '../footer/about';
import Legal from '../footer/legal';
import Services from '../footer/services';
import Contact from '../footer/contact';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '80%', md: '60%' },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: { xs: 2, sm: 3, md: 4 },
  overflow: 'auto',
  maxHeight: { xs: '80vh', sm: '70vh', md: '60vh' },
  borderRadius: 1
};

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);

  const updateContent = (type) => {
    switch(type) {
      case 'aboutUs':
        setContent(<About />);
        break;
      case 'legal':
        setContent(<Legal />);
        break;
      case 'services':
        setContent(<Services />);
        break;
      case 'contact':
        setContent(<Contact />);
        break;
      default:
        setContent(null);
    }
  };

  const handleOpen = (event, type) => {
    event.preventDefault();
    updateContent(type);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div style={{ width: '100%' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-description"
      >
        <Box sx={style}>
          {content}
        </Box>
      </Modal>
      
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item>
          <Button 
            href='#' 
            onClick={(event) => handleOpen(event, 'aboutUs')}
            style={{ 
              color: '#666', 
              textTransform: 'none',
              padding: '6px 8px',
              minWidth: 'auto',
              fontSize: '0.875rem'
            }}
          >
            About Us
          </Button>
        </Grid>
        <Grid item>
          <Button 
            href='#' 
            onClick={(event) => handleOpen(event, 'services')}
            style={{ 
              color: '#666', 
              textTransform: 'none',
              padding: '6px 8px',
              minWidth: 'auto',
              fontSize: '0.875rem'
            }}
          >
            Services
          </Button>
        </Grid>
        <Grid item>
          <Button 
            href='#' 
            onClick={(event) => handleOpen(event, 'contact')}
            style={{ 
              color: '#666', 
              textTransform: 'none',
              padding: '6px 8px',
              minWidth: 'auto',
              fontSize: '0.875rem'
            }}
          >
            Contact Us
          </Button>
        </Grid>
        <Grid item>
          <Button 
            href='#' 
            onClick={(event) => handleOpen(event, 'legal')}
            style={{ 
              color: '#666', 
              textTransform: 'none',
              padding: '6px 8px',
              minWidth: 'auto',
              fontSize: '0.875rem'
            }}
          >
            Legal
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
