import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const About = () => {
  return (
    <Box className="about-page" sx={{ 
      padding: { xs: '16px', sm: '20px', md: '40px' },
      color: '#333'
    }}>
      <Container maxWidth="md">
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          sx={{ 
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: { xs: '20px', sm: '25px', md: '30px' },
            color: '#1a73e8',
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.125rem' }
          }}
        >
          About Linkout
        </Typography>

        <Box sx={{ 
          '& p': {
            marginBottom: '1.2em',
            lineHeight: '1.7',
            fontSize: { xs: '0.95rem', md: '1rem' },
            color: '#555'
          }
        }}>
          <Typography paragraph>
            Linkout is dedicated to empowering professionals in making informed career decisions through data-driven insights.
          </Typography>

          <Typography paragraph>
            Founded by experienced software engineers, we understand the challenges professionals face: evaluating skill relevance, measuring career progression, planning paths to desired organizations, and benchmarking against industry peers.
          </Typography>

          <Typography paragraph>
            While these career aspects can be complex to evaluate individually, our platform provides comprehensive analytics and industry-wide perspectives to help you make informed decisions.
          </Typography>

          <Typography paragraph>
            Through advanced data analysis, we provide actionable insights tailored to your professional journey.
          </Typography>

          <Typography paragraph>
            Explore our Services page to discover our full range of offerings. We're committed to keeping our platform completely free - no credit card or payment required.
          </Typography>

          <Typography paragraph>
            To begin your assessment, simply submit your professional achievements and career milestones. Our AI-powered backend system validates all documents for accuracy and authenticity, ensuring our analysis is based on verified data to deliver reliable, real-world insights.
          </Typography>

          <Typography paragraph>
            For additional information or inquiries, please don't hesitate to reach out via the contact details provided on our Contact Us page.
          </Typography>

          <Typography paragraph>
            We look forward to supporting you on your professional journey.
          </Typography>

          <Box sx={{ 
            marginTop: '40px',
            textAlign: 'right',
            paddingRight: { xs: '20px', md: '40px' }
          }}>
            <Typography paragraph>
              Best regards,
            </Typography>

            <Typography 
              paragraph 
              sx={{ 
                fontStyle: 'italic',
                color: '#1a73e8',
                fontWeight: 500
              }}
            >
              The Linkout Team
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default About;
