import React from 'react';
import { Modal, Box, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BasicModal = ({ open, handleClose, message }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : '70%',
                maxHeight: '90vh',
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                overflow: 'auto',
                '&:focus': {
                    outline: 'none',
                },
            }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography 
                    id="modal-title" 
                    variant={isMobile ? "h6" : "h5"} 
                    component="h2"
                    color="primary"
                    sx={{ mb: 2, pr: 4 }}
                >
                    {message?.title || 'Analysis Results'}
                </Typography>

                <Box 
                    id="modal-description"
                    sx={{
                        mt: 2,
                        maxHeight: '70vh',
                        overflow: 'auto',
                        '& p': {
                            marginBottom: '1em',
                            lineHeight: 1.6,
                        },
                        '& ul, & ol': {
                            marginLeft: '1.5em',
                            marginBottom: '1em',
                        },
                        '& li': {
                            marginBottom: '0.5em',
                        },
                        // Style for highlighting important points
                        '& strong': {
                            color: theme.palette.primary.main,
                        },
                        // Add some spacing between sections
                        '& h3, & h4': {
                            marginTop: '1.5em',
                            marginBottom: '0.75em',
                            color: theme.palette.primary.main,
                        },
                    }}
                >
                    {typeof message?.body === 'string' ? (
                        <Typography variant="body1" component="div">
                            {message.body.split('\n').map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </Typography>
                    ) : (
                        <Typography variant="body1">
                            {message?.body || 'No analysis available.'}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default BasicModal;
