import React from 'react';
import { Typography, Box, Container, Paper, Grid } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import WarningIcon from '@mui/icons-material/Warning';
import CopyrightIcon from '@mui/icons-material/Copyright';

const Legal = () => {
  return (
    <Box className="legal-page" sx={{ 
      padding: { xs: '20px', md: '40px' },
      color: '#333'
    }}>
      <Container maxWidth="md">
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          sx={{ 
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: '30px',
            color: '#1a73e8'
          }}
        >
          Legal Information
        </Typography>

        <Grid container spacing={{ xs: 2, sm: 2, md: 3 }}>
          {/* Privacy Policy Section */}
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ 
              p: { xs: 2, sm: 2, md: 3 },
              backgroundColor: '#f8f9fa'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <SecurityIcon sx={{ color: '#1a73e8' }} />
                <Typography variant="h6" sx={{ color: '#1a73e8', fontWeight: 500 }}>
                  Privacy Policy
                </Typography>
              </Box>
              <Typography paragraph>
                This Privacy Policy outlines how Linkout ("we," "our," or "us") collects, uses, maintains, and discloses information collected from users. By accessing or using our platform, you acknowledge and fully accept our Privacy Policy and consent to the collection and processing of your information as described herein.
              </Typography>
            </Paper>
          </Grid>

          {/* Disclaimer and Risk Section */}
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ 
              p: { xs: 2, sm: 2, md: 3 },
              backgroundColor: '#f8f9fa'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <WarningIcon sx={{ color: '#1a73e8' }} />
                <Typography variant="h6" sx={{ color: '#1a73e8', fontWeight: 500 }}>
                  Disclaimer and Risk Acknowledgment
                </Typography>
              </Box>
              <Typography paragraph>
                THE SERVICES AND CONTENT PROVIDED BY LINKOUT ARE ON AN "AS IS" AND "AS AVAILABLE" BASIS. USERS ACKNOWLEDGE AND AGREE TO THE FOLLOWING:
              </Typography>
              <Box component="ul" sx={{ pl: 3, mb: 2 }}>
                <Typography component="li" paragraph>
                  Linkout does not assume ownership of any documents, information, or data submitted to the platform. Users retain full ownership and responsibility for their submitted materials.
                </Typography>
                <Typography component="li" paragraph>
                  The analysis, insights, and information provided through our platform are for informational purposes only and should not be construed as professional advice.
                </Typography>
                <Typography component="li" paragraph>
                  Users assume full responsibility and risk for any decisions made based on information obtained through our platform.
                </Typography>
                <Typography component="li" paragraph>
                  Linkout makes no warranties, expressed or implied, regarding the accuracy, completeness, or reliability of any information provided through the platform.
                </Typography>
                <Typography component="li" paragraph>
                  Users acknowledge that career-related decisions involve inherent risks, and Linkout shall not be held liable for any consequences resulting from the use of our platform or services.
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Limitation of Liability */}
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ 
              p: { xs: 2, sm: 2, md: 3 },
              backgroundColor: '#f8f9fa'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <GavelIcon sx={{ color: '#1a73e8' }} />
                <Typography variant="h6" sx={{ color: '#1a73e8', fontWeight: 500 }}>
                  Limitation of Liability
                </Typography>
              </Box>
              <Typography paragraph>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, LINKOUT AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE FOR ANY:
              </Typography>
              <Box component="ul" sx={{ pl: 3, mb: 2 }}>
                <Typography component="li" paragraph>
                  Direct, indirect, incidental, special, consequential, or exemplary damages
                </Typography>
                <Typography component="li" paragraph>
                  Loss of profits, data, use, goodwill, or other intangible losses
                </Typography>
                <Typography component="li" paragraph>
                  Damages resulting from any decisions made based on information provided through our platform
                </Typography>
                <Typography component="li" paragraph>
                  Damages arising from unauthorized access to or use of our servers or any personal information
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Copyright Notice */}
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ 
              p: { xs: 2, sm: 2, md: 3 },
              backgroundColor: '#f8f9fa'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <CopyrightIcon sx={{ color: '#1a73e8' }} />
                <Typography variant="h6" sx={{ color: '#1a73e8', fontWeight: 500 }}>
                  Copyright and Intellectual Property
                </Typography>
              </Box>
              <Typography paragraph>
                All content on this platform, including but not limited to text, graphics, logos, icons, images, and software, is the property of Linkout or its content suppliers and is protected by international copyright laws. The compilation of all content on this platform is the exclusive property of Linkout and protected by international copyright laws.
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="body2" color="textSecondary">
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Legal;
