import React ,{useState}from 'react'
import { useForm } from 'react-hook-form'
import {TextField, Button, LinearProgress} from '@material-ui/core'
import * as Yup from 'yup'
import Util from '../utils/Util'
import MuiAlert from '@material-ui/lab/Alert';

import MyAlert from './Alerts'

 const SignupLatest = () =>{
  var alert ='';
  const prepareAlert = (type, message) =>{
      //console.log(type, message)
     alert =  <MyAlert type={type} message={message}/>;
  }

    const [displayAlert,setDisplayAlert] = useState('');
    const [submitFlag, setSubmitFlag] = useState(false)
    

    const { formState: { errors } , reset, register, handleSubmit } = useForm()

    const Alert =(props) =>{
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
  const onSubmit = async data => {
    setSubmitFlag(true)
           
    
           
           let response =  await Util.loginUser(data)
           //console.log(JSON.stringify(response))
           if(response === 'success'){
                setSubmitFlag(false);
                await reset()
                await prepareAlert('success', "User has been created. Please check your email to verify and then try signing in.")
                await setDisplayAlert(alert)    
           }else{
            //console.log('error occured')
            await prepareAlert('error', "There is a error in form submission. Email has to be in correct format and password should be minimum 7 characters ")
                setSubmitFlag(false);
                await setDisplayAlert(alert)            
                
           }
    
  }

  return (
    <div style={{ padding: '20px' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Sign Up</h2>
        
        {displayAlert}   
    
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ marginBottom: '16px' }}>
                <TextField 
                    error={!!errors.firstName} 
                    type="text" 
                    name="firstName" 
                    label="First Name"  
                    {...register('firstName', { required: true})} 
                    variant="outlined" 
                    fullWidth 
                />
                {errors.firstName && errors.firstName.type === "required" && 
                    <span style={{ color: 'red', fontSize: '0.8rem' }}>First Name is required</span>}  
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TextField 
                    error={!!errors.middleName} 
                    type="text" 
                    name="middleName" 
                    label="Middle Name"  
                    {...register('middleName')} 
                    variant="outlined" 
                    fullWidth 
                />
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TextField 
                    error={!!errors.lastName} 
                    type="text" 
                    name="lastName" 
                    label="Last Name"  
                    {...register('lastName', { required: true})} 
                    variant="outlined" 
                    fullWidth 
                />
                {errors.lastName && errors.lastName.type === "required" && 
                    <span style={{ color: 'red', fontSize: '0.8rem' }}>Last Name is required</span>}  
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TextField 
                    error={!!errors.email} 
                    type="text" 
                    name="Email" 
                    label="Email"  
                    {...register('email', 
                    { required: true, 
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                    })} 
                    variant="outlined" 
                    fullWidth 
                />
                {errors.email && errors.email.type === "required" && 
                    <span style={{ color: 'red', fontSize: '0.8rem' }}>Email is required</span>}
                {errors.email && errors.email.type === "pattern" && 
                    <span style={{ color: 'red', fontSize: '0.8rem' }}>Email should be in proper format</span>}    
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TextField 
                    error={!!errors.password} 
                    type="password" 
                    name="Password" 
                    label="Password"  
                    {...register('password', { required: true, minLength : 7})} 
                    variant="outlined" 
                    fullWidth 
                />
                {errors.password && errors.password.type === "required" && 
                    <span style={{ color: 'red', fontSize: '0.8rem' }}>Password is required</span>}  
                {errors.password && errors.password.type === "minLength" && 
                    <span style={{ color: 'red', fontSize: '0.8rem' }}>Password Should be minimum 7 characters long</span>}  
            </div>

            {submitFlag ? 
                <div style={{ margin: '16px 0' }}>
                    <Alert severity="info">Hang tight! First request may take some time.</Alert> 
                    <LinearProgress /> 
                </div> 
                : null
            }
            
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit" 
                    disabled={submitFlag} 
                    size="large"
                    fullWidth
                >
                    Sign Up 
                </Button>
            </div>
        </form>
    </div>
  );
}

export default SignupLatest