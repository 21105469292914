import React from 'react'
import {Grid, Typography, List, ListItem, ListItemText, Paper} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SimilarityCharts from './similarityCharts'
import { Divider } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import Chip from '@material-ui/core/Chip';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Comparision from './Comparision'
const ChartDashboard =(props)=>{
    const [activeTab, setActiveTab] = React.useState(0);
    
    console.log("User Record:", props.userRecord);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div style={{ padding: '20px' }}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="My Profile" />
                <Tab label="Compare with Peers" />
                <Tab label="Find Your Fit" />
            </Tabs>

            {activeTab === 0 && (
                <div style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        {/* Personal Information Card */}
                        <Grid item xs={12} >
                            <Paper elevation={2} style={{ padding: '24px', borderRadius: '12px' }}>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    Personal Information
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" color="textSecondary">First Name</Typography>
                                        <Typography variant="body1">
                                            {props.userRecord?.name || props.userRecord?.firstName || 'Not specified'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" color="textSecondary">Middle Name</Typography>
                                        <Typography variant="body1">
                                            {props.userRecord?.name || props.userRecord?.middleName || 'Not specified'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" color="textSecondary">Last Name</Typography>
                                        <Typography variant="body1">
                                            {props.userRecord?.name || props.userRecord?.lastName || 'Not specified'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2" color="textSecondary">Age</Typography>
                                        <Typography variant="body1">
                                            {props.userRecord?.age || 'Not specified'}
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">Email</Typography>
                                        <Typography 
                                            variant="body1" 
                                            style={{ 
                                                wordBreak: 'break-all',
                                                overflowWrap: 'break-word',
                                                maxWidth: '100%',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {props.userRecord?.Auth?.userName || 'Not specified'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2" color="textSecondary">City</Typography>
                                        <Typography variant="body1">
                                            {props.userRecord?.NativeCity?.City?.name || 'Not specified'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2" color="textSecondary">State</Typography>
                                        <Typography variant="body1">
                                            {props.userRecord?.NativeCity?.City?.state || 'Not specified'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        
                        {/* Skills Card */}
                        <Grid item xs={12}>
                            <Paper elevation={2} style={{ padding: '24px', borderRadius: '12px' }}>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    Skills
                                </Typography>
                                <Grid container spacing={1}>
                                    {props.userRecord.Skills?.map((skill) => (
                                        <Grid item key={skill.name}>
                                            <Chip 
                                                label={skill.name}
                                                color="primary"
                                                variant="outlined"
                                                style={{ margin: '4px' }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Work Experience Card */}
                        <Grid item xs={12}>
                            <Paper elevation={2} style={{ padding: '24px', borderRadius: '12px' }}>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    Work Experience
                                </Typography>
                                {props.userRecord.CompanyUsers?.map((company) => (
                                    <div key={company.name} style={{ marginBottom: '16px' }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            {company.name}
                                        </Typography>
                                        <Grid container spacing={2} style={{ marginTop: '8px' }}>
                                            <Grid item xs={6} sm={4}>
                                                <Typography variant="subtitle2" color="textSecondary">CTC</Typography>
                                                <Typography variant="body2">{company?.Ctcs[0]?.ctc}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <Typography variant="subtitle2" color="textSecondary">Bonus</Typography>
                                                <Typography variant="body2">{company?.Ctcs[0]?.bonus}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <Typography variant="subtitle2" color="textSecondary">Designation</Typography>
                                                <Typography variant="body2">{company?.Ctcs[0]?.designation || company?.designation || 'Not specified'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            )}
            
            {activeTab === 2 && (
                <div style={{ marginTop: '20px' }}>
                    <Comparision userId={props.userRecord.id} />
                </div>
            )}
            
            {activeTab === 1 && (
                <div style={{ marginTop: '20px' }}>
                    <SimilarityCharts 
                        userId={props.userRecord.id}
                        designation={props.userRecord.designation}
                        graduationCollege={props.userRecord.graduationCollege}
                        postGradCollege={props.userRecord.postGraduationCollege}
                        workCity={props.userRecord.workCity}
                    />
                </div>
            )}
        </div>
    );
}

export default ChartDashboard