import React ,{useState}from 'react'
import { useForm } from 'react-hook-form'
import {TextField, Button, LinearProgress, Typography, Box, Link} from '@material-ui/core'
import * as Yup from 'yup'
import Auth from '../utils/Auth'
import {useHistory} from "react-router-dom";
import MyAlert from './Alerts'
import PasswordReset from './PasswordResetRequest'
import MuiAlert from '@material-ui/lab/Alert';

 const Login = () =>{
  const Alert =(props) =>{
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
    const history = useHistory();
    const [displayAlert,setDisplayAlert] = useState('');
    const [submitFlag, setSubmitFlag] = useState(false)
    const schemaValidation = Yup.object().shape({
        email : Yup.string().required('Email is required'),
        password : Yup.string().required('Password is required').min(8,'min 8 character is needed')
      })

  const { formState: { errors } , register, handleSubmit } = useForm({
    validationSchema:schemaValidation
  })
  
  const onSubmit = async data => {
    setSubmitFlag(true)
    
    Auth.loginPromise(data)
    .then((isAdmin)=>{
     setSubmitFlag(false)
     history.push('/')
     window.location.reload()
    })
    .catch((error)=>{
      setSubmitFlag(false)
      setDisplayAlert('')
      Auth.isSessionActive=false;
      setDisplayAlert(<MyAlert type={'error'} message={error}/>)
    })
   }

   const alertHandler = ()=>{
    setDisplayAlert( <Alert severity="info">password reset request received</Alert>)
   }

  return (
    <Box p={3}>
        <Typography variant="h5" component="h1" gutterBottom align="center" style={{
            marginBottom: '24px',
            fontWeight: 500,
            color: '#1a73e8'
        }}>
            Log in to Linkout
        </Typography>

        {displayAlert}

        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ marginBottom: '16px' }}>
                <TextField 
                    error={!!errors.email}
                    type="text"
                    name="email"
                    label="Email"
                    {...register('email', { required: true })}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        style: { borderRadius: '8px' }
                    }}
                />
                {errors.email && errors.email.type === "required" && 
                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                        Email is required
                    </Typography>
                }
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TextField 
                    error={!!errors.password}
                    type="password"
                    name="password"
                    label="Password"
                    {...register('password', { required: true })}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        style: { borderRadius: '8px' }
                    }}
                />
                {errors.password && errors.password.type === "required" && 
                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                        Password is required
                    </Typography>
                }
            </div>

            {submitFlag && (
                <Box my={2}>
                    <Alert severity="info">Hang tight! First request may take some time.</Alert>
                    <LinearProgress style={{ marginTop: '8px' }} />
                </Box>
            )}

            <Box mt={2}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit" 
                    disabled={submitFlag}
                    fullWidth
                    size="large"
                    style={{
                        borderRadius: '8px',
                        padding: '12px',
                        fontSize: '1rem',
                        textTransform: 'none',
                        backgroundColor: '#1a73e8',
                        '&:hover': {
                            backgroundColor: '#1557b0'
                        }
                    }}
                >
                    Log In
                </Button>
            </Box>

            <Box mt={2} textAlign="center">
                <PasswordReset showAlert={alertHandler} />
            </Box>
        </form>

        
    </Box>
  );
}

export default Login